var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"side-block"},_vm._l((_vm.blockList),function(item){return _c('small-block',{key:item.id,class:['small-block', _vm.active === item.title ? 'active' : ''],attrs:{"item":item,"borderStyle":_vm.active === item.title,"bgColor":_vm.active === item.title},nativeOn:{"click":function($event){_vm.active = item.title !== '新风系统' ? item.title : _vm.active;
        _vm.changeBlock(item.title);}}})}),1),_c('div',{staticClass:"table-container"},[_c('table-tab',{attrs:{"tabs":_vm.tabs,"active":_vm.active_id},on:{"changeTab":_vm.changeTab}}),_c('div',{staticClass:"table"},[_c('div',{staticClass:"table-info",style:({
          background: _vm.nowIcon.bgColor,
          borderColor: _vm.nowIcon.borderColor,
        })},[_c('div',{staticClass:"icon-container",style:({
            color: _vm.nowIcon.borderColor,
            borderColor: _vm.nowIcon.borderColor,
          })},[_c('i',{class:['iconfont', _vm.nowIcon.icon, 'icon-position']})]),_c('div',{staticClass:"text-container"},[_c('span',[_c('span',{staticStyle:{"color":"#e73838"}},[_vm._v(_vm._s(_vm.sum))]),_vm._v(_vm._s(_vm.nowIcon.text)+" ")])])]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingController),expression:"loadingController"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"fit":"","height":"480"}},[_c('el-table-column',{attrs:{"prop":"filter_type","label":"滤网类型"}},[[_c('span',[_vm._v(_vm._s(_vm.$route.query.title))])]],2),_c('el-table-column',{attrs:{"prop":"className","label":"所属场景"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(" "+_vm._s(scope.row.scene))])]}}])}),_c('el-table-column',{attrs:{"prop":"device_state","label":"设备状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"device_state"},[(scope.row.activeStatus === 1)?_c('span',[_vm._v("已开机")]):(scope.row.activeStatus === 0)?_c('span',[_vm._v("已关机")]):_c('span',[_vm._v("已离线")])])]}}])}),_c('el-table-column',{attrs:{"prop":"used","label":"已使用"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.filterused))])]}}])}),_c('el-table-column',{attrs:{"label":"剩余时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.filterremaining)+"小时")])]}}])}),_c('el-table-column',{attrs:{"label":"滤网状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.filterStatus))])]}}])}),_c('el-table-column',{attrs:{"label":"进度条","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-progress',{attrs:{"stroke-width":26,"percentage":scope.row.filterused &&
                100 - parseInt(scope.row.filterused.split('%')),"format":_vm.showText,"color":_vm.progressColors}})]}}])}),_c('el-table-column',{attrs:{"label":"重置","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(parseInt(scope.row.filterused.split('%')) > 95)?_c('div',{staticClass:"filter-progress-btn",on:{"click":function($event){return _vm.resetFilter(scope.row)}}},[_c('img',{attrs:{"src":require("../../assets/images/fw.png"),"alt":""}})]):_c('div',{staticClass:"filter-progress-btn-none"},[_c('img',{attrs:{"src":require("../../assets/images/fw.png"),"alt":""}})])]}}])})],1)],1)],1),_c('width-dialog',{attrs:{"property":_vm.property,"width":"350px"},on:{"cancel":_vm.dialogCancel,"confirm":_vm.dialogConfirm}},[_c('div',{staticClass:"filter-dialog-reset"},[_c('div',[_vm._v(" "+_vm._s(_vm.$route.query.title)+": 剩余 "),_c('span',{staticStyle:{"color":"#e83934"}},[_vm._v(_vm._s(_vm.currentFilter.remainP))]),_vm._v(" 剩余时间："),_c('span',{staticStyle:{"color":"#e83934"}},[_vm._v(_vm._s(_vm.currentFilter.remainH))]),_vm._v("小时 ")]),_c('div',[_vm._v("如您尚未更换滤网, 请及时更换滤网!")]),_c('div',[_vm._v("如果您已更换滤网，请点击复位!")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
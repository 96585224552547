<template>
  <div class="container">
    <div class="side-block">
      <small-block
        v-for="item in blockList"
        :key="item.id"
        :item="item"
        :class="['small-block', active === item.title ? 'active' : '']"
        @click.native="
          active = item.title !== '新风系统' ? item.title : active;
          changeBlock(item.title);
        "
        :borderStyle="active === item.title"
        :bgColor="active === item.title"
      ></small-block>
    </div>
    <div class="table-container">
      <table-tab
        :tabs="tabs"
        @changeTab="changeTab"
        :active="active_id"
      ></table-tab>
      <div class="table">
        <div
          class="table-info"
          :style="{
            background: nowIcon.bgColor,
            borderColor: nowIcon.borderColor,
          }"
        >
          <div
            class="icon-container"
            :style="{
              color: nowIcon.borderColor,
              borderColor: nowIcon.borderColor,
            }"
          >
            <i :class="['iconfont', nowIcon.icon, 'icon-position']"></i>
          </div>
          <div class="text-container">
            <span>
              <span style="color: #e73838">{{ sum }}</span
              >{{ nowIcon.text }}
            </span>
          </div>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          fit
          height="480"
          v-loading="loadingController"
        >
          <el-table-column prop="filter_type" label="滤网类型">
            <template>
              <span>{{ $route.query.title }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="className" label="所属场景">
            <template slot-scope="scope">
              <span> {{ scope.row.scene }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="device_state" label="设备状态">
            <template slot-scope="scope">
              <div class="device_state">
                <span v-if="scope.row.activeStatus === 1">已开机</span>
                <span v-else-if="scope.row.activeStatus === 0">已关机</span>
                <span v-else>已离线</span>
                <!--                <el-switch-->
                <!--                  :value="scope.row.device_state === 1"-->
                <!--                  disabled-->
                <!--                  active-color="#01cfa5"-->
                <!--                ></el-switch>-->
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="used" label="已使用">
            <template slot-scope="scope">
              <span>{{ scope.row.filterused }}</span>
            </template>
          </el-table-column>
          <el-table-column label="剩余时间">
            <template slot-scope="scope">
              <span>{{ scope.row.filterremaining }}小时</span>
            </template>
          </el-table-column>
          <el-table-column label="滤网状态">
            <template slot-scope="scope">
              <span>{{ scope.row.filterStatus }}</span>
              <!--              <span v-if="scope.row.filterused <= 95">已更换</span>-->
              <!--              <div v-else class="exchange">-->
              <!--                <img src="../../assets/images/js-y.png" alt="" />-->
              <!--                <span>请更换</span>-->
              <!--              </div>-->
            </template>
          </el-table-column>
          <el-table-column label="进度条" width="250">
            <template slot-scope="scope">
              <el-progress
                :stroke-width="26"
                :percentage="
                  scope.row.filterused &&
                  100 - parseInt(scope.row.filterused.split('%'))
                "
                :format="showText"
                :color="progressColors"
              ></el-progress>
            </template>
          </el-table-column>
          <el-table-column label="重置" width="60">
            <template slot-scope="scope">
              <div
                class="filter-progress-btn"
                @click="resetFilter(scope.row)"
                v-if="parseInt(scope.row.filterused.split('%')) > 95"
              >
                <img src="../../assets/images/fw.png" alt="" />
              </div>
              <div class="filter-progress-btn-none" v-else>
                <img src="../../assets/images/fw.png" alt="" />
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <width-dialog
      :property="property"
      width="350px"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
    >
      <div class="filter-dialog-reset">
        <div>
          {{ $route.query.title }}: 剩余
          <span style="color: #e83934">{{ currentFilter.remainP }}</span>
          剩余时间：<span style="color: #e83934">{{
            currentFilter.remainH
          }}</span
          >小时
        </div>
        <div>如您尚未更换滤网, 请及时更换滤网!</div>
        <div>如果您已更换滤网，请点击复位!</div>
      </div>
    </width-dialog>
  </div>
</template>

<script>
import { SmallBlock } from "../../components/widget";
import { TableTab } from "../../components/layout";
import WidthDialog from "../../components/dialog/WidthDialog";

export default {
  name: "FilterManage",
  components: {
    SmallBlock,
    TableTab,
    WidthDialog,
  },
  data() {
    return {
      property: {
        show: false,
        title: "滤网复位",
      },
      currentFilter: {
        remainP: 0,
        remainH: 0,
        device: null,
      },
      loadingController: false,
      blockList: [
        {
          id: 1,
          title: "新风系统",
          num: 0,
          extra: "",
        },
        {
          id: 2,
          title: "初效滤网",
          num: 0,
          extra: "",
        },
        {
          id: 3,
          title: "中效滤网",
          num: 0,
          extra: "",
        },
        {
          id: 4,
          title: "高效滤网",
          num: 0,
        },
      ],
      active: "",
      active_id: 1,
      tabs: [
        {
          id: 1,
          title: "所有初效滤网",
        },
        {
          id: 2,
          title: "需更换",
        },
        // {
        //   id: 2,
        //   title: "初效滤网",
        // },
        // {
        //   id: 3,
        //   title: "中效滤网",
        // },
        // {
        //   id: 4,
        //   title: "高效滤网",
        // },
      ],
      resultData: [],
      tableData: [],
      progressColors: [
        { color: "#41c057", percentage: 100 },
        { color: "#e73838", percentage: 10 },
      ],
      nowIcon: {
        icon: "icon-lvwang",
        text: "台设备需更换初效滤网 ,初效滤网更换周期为1个月,初效滤网主要用于过滤 5μm 以上颗粒,过滤效率可达90%，可有效过滤......",
        style: "backgroundColor: '#e9f5ff'; borderColor: '#1594fd'",
        borderColor: "#1594fd",
        bgColor: "#e9f5ff",
      },
      iconItem: {
        low: {
          icon: "icon-lvwang",
          text: "台设备需更换初效滤网 ,初效滤网更换周期为1个月,初效滤网主要用于过滤 5μm 以上颗粒,过滤效率可达90%，可有效过滤......",
          borderColor: "#1594fd",
          bgColor: "#e9f5ff",
        },
        middle: {
          icon: "icon-caozuo_lvwang",
          text: "台设备需更换中效滤网 ,中效滤网更换周期为1个月,初效滤网主要用于过滤 5μm 以上颗粒,过滤效率可达90%，可有效过滤......",
          borderColor: "#01c2b3",
          bgColor: "#ebfffd",
        },
        high: {
          icon: "icon-lvwangzhongzhi",
          text: "台设备需更换高效滤网 ,高效滤网更换周期为1个月,初效滤网主要用于过滤 5μm 以上颗粒,过滤效率可达90%，可有效过滤......",
          borderColor: "#58c328",
          bgColor: "#f1ffea",
        },
      },
      sum: 0,
    };
  },
  methods: {
    //弹窗点击取消
    dialogCancel() {
      this.property.show = false;
    },
    //弹窗点击确认
    dialogConfirm() {
      this.property.show = false;
      this.loadingController = true;
      const device = this.currentFilter.device;
      const title = this.$route.query.title;
      let params = this.getFilterHour(title);
      params.entityId = device.deviceId;
      this.loadingController = true;
      this.$http
        .filterReset(params)
        .then((res) => {
          console.log(res);
          this.detailFilterState(title);
          this.loadingController = false;
          this.$message({
            message: "复位成功",
            type: "success",
          });
        })
        .catch(() => {
          this.loadingController = false;
        });
    },
    //获取滤网总小时
    getFilterHour(title) {
      if (title === "初效滤网") {
        this.$store.state.filterListPrimaryRequire = false;
        return { attributeKey: "primaryFilter", filterValue: 1080000000 };
      }
      if (title === "中效滤网") {
        this.$store.state.filterListMediumRequire = false;
        return { attributeKey: "mediumFilter", filterValue: 720000000 };
      }
      if (title === "高效滤网") {
        this.$store.state.filterListHighRequire = false;
        return { attributeKey: "highFilter", filterValue: 360000000 };
      }
    },
    // 复位滤网
    resetFilter(device) {
      // console.log(device);
      // console.log(title);
      this.currentFilter.remainH = device.filterremaining;
      this.currentFilter.remainP = device.filterused;
      this.currentFilter.device = device;
      this.property.show = true;
      // this.loadingController = true
      // let params = this.getFilterHour(title);
      // params.entityId = device.deviceId;
      // this.$http
      //   .filterReset(params)
      //   .then((res) => {
      //     console.log(res);
      //     this.detailFilterState(title);
      //     this.loadingController = false;
      //     this.$message({
      //       message: "复位成功",
      //       type: "success",
      //     });
      //   })
      //   .catch(() => {
      //     this.loadingController = false;
      //   });

      // this.$confirm("确定要重置吗")
      //   .then((res) => {
      //     if (res) {
      //       this.loadingController = true;
      //       let params = this.getFilterHour(title);
      //       params.entityId = id;
      //
      //       }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.loadingController = false;
      //   });
    },
    filterSum(title) {
      if (title === "初效滤网") {
        this.sum = this.$store.state.filterState.primaryFilter;
      } else if (title === "中效滤网") {
        this.sum = this.$store.state.filterState.mediumFilter;
      } else {
        this.sum = this.$store.state.filterState.highFilter;
      }
    },
    changeBlock(title) {
      if (title === "新风系统") {
        this.$router.push({
          path: "/detail/maintain",
        });
      } else {
        if (this.$route.query.title !== title) {
          this.$router.push({
            path: "/detail/filter",
            query: { title },
          });
          this.detailFilterState(title);
          this.filterSum(title);
          if (title === "初效滤网") {
            this.active_id = 1;
            this.tabs[0].title = "所有初效滤网";
            this.nowIcon = this.iconItem.low;
            // this.tableData = this.resultData.filter((item) => {
            //   return item.filter_type === "初效滤网";
            // });
          } else if (title === "中效滤网") {
            this.active_id = 1;
            this.tabs[0].title = "所有中效滤网";
            this.nowIcon = this.iconItem.middle;
            // this.tableData = this.resultData.filter((item) => {
            //   return item.filter_type === "中效滤网";
            // });
          } else if (title === "高效滤网") {
            this.active_id = 1;
            this.tabs[0].title = "所有高效滤网";
            this.nowIcon = this.iconItem.high;
            // this.tableData = this.resultData.filter((item) => {
            //   return item.filter_type === "高效滤网";
            // });
          }
        }
      }
    },
    changeTab(data) {
      const id = data.id;
      if (id === this.active_id) {
        return;
      }
      if (id === 2) {
        this.active_id = 2;
        this.tableData = this.tableData.filter((item) => {
          return item.filterStatus === "需更换";
        });
      }
      if (id === 1) {
        const title = this.$route.query.title;
        if (title === "初效滤网") {
          this.active_id = 1;
          this.tableData = [...this.resultData];
        } else if (title === "中效滤网") {
          this.active_id = 1;
          this.tableData = [...this.resultData];
        } else if (title === "高效滤网") {
          this.active_id = 1;
          this.tableData = [...this.resultData];
        }
      }
    },
    detailFilterState(title) {
      let type;
      if (title === "初效滤网") {
        type = "primary";
        if (this.$store.state.filterListPrimaryRequire) {
          this.resultData = this.$store.state.maintainedPrimaryFilterList;
          this.tableData = [...this.resultData];
        } else {
          this.$store.dispatch("getMaintainedFilterList", type).then(() => {
            this.resultData = this.$store.state.maintainedPrimaryFilterList;
            this.tableData = [...this.resultData];
          });
        }
      } else if (title === "中效滤网") {
        type = "medium";
        if (this.$store.state.filterListMediumRequire) {
          this.resultData = this.$store.state.maintainedMediumFilterList;
          this.tableData = [...this.resultData];
        } else {
          this.$store.dispatch("getMaintainedFilterList", type).then(() => {
            this.resultData = this.$store.state.maintainedMediumFilterList;
            this.tableData = [...this.resultData];
          });
        }
      } else {
        type = "high";
        if (this.$store.state.filterListHighRequire) {
          this.resultData = this.$store.state.maintainedHighFilterList;
          this.tableData = [...this.resultData];
        } else {
          this.$store.dispatch("getMaintainedFilterList", type).then(() => {
            this.resultData = this.$store.state.maintainedHighFilterList;
            this.tableData = [...this.resultData];
          });
        }
      }
    },
    // changeTab(data) {
    //   const title =
    //     data.id === 2 ? "初效滤网" : data.id === 3 ? "中效滤网" : "高效滤网";
    //   if (data.id === 1) {
    //     this.active_id = 1;
    //     this.active = "";
    //     this.$router.push({
    //       query: "",
    //     });
    //     this.tableData = [...this.resultData];
    //   } else if (data.id === 2) {
    //     this.active_id = 2;
    //     this.active = "初效滤网";
    //     this.$router.push({
    //       query: { title },
    //     });
    //     this.tableData = this.resultData.filter((item) => {
    //       return item.filter_type === "初效滤网";
    //     });
    //   } else if (data.id === 3) {
    //     this.active_id = 3;
    //     this.active = "中效滤网";
    //     this.$router.push({
    //       query: { title },
    //     });
    //     this.tableData = this.resultData.filter((item) => {
    //       return item.filter_type === "中效滤网";
    //     });
    //   } else if (data.id === 4) {
    //     this.active_id = 4;
    //     this.active = "高效滤网";
    //     this.$router.push({
    //       query: { title },
    //     });
    //     this.tableData = this.resultData.filter((item) => {
    //       return item.filter_type === "高效滤网";
    //     });
    //   }
    // },
    showText(p) {
      return `剩余${p}%`;
    },
  },
  created() {
    console.log("filter");
    if (this.$store.state.detailRequireConfirm) {
      this.blockList[0].num = this.$store.state.airState;
      this.blockList[1].num = this.$store.state.filterState.primaryFilter;
      this.blockList[2].num = this.$store.state.filterState.mediumFilter;
      this.blockList[3].num = this.$store.state.filterState.highFilter;
    } else {
      this.$store.dispatch("getAirStateAction").then(() => {
        this.blockList[0].num = this.$store.state.airState;
      });
      this.$store.dispatch("getFilterState").then(() => {
        this.blockList[1].num = this.$store.state.filterState.primaryFilter;
        this.blockList[2].num = this.$store.state.filterState.mediumFilter;
        this.blockList[3].num = this.$store.state.filterState.highFilter;
      });
    }
    this.$store.commit("changeDetailRequireConfirm");
    // this.blockList[0].num = this.$store.state.airState;
    // this.blockList[1].num = this.$store.state.filterState.primaryFilter;
    // this.blockList[2].num = this.$store.state.filterState.mediumFilter;
    // this.blockList[3].num = this.$store.state.filterState.highFilter;
    const title = this.$route.query.title;
    console.log(title, "title");
    this.detailFilterState(title);
    this.filterSum(title);
    if (title) {
      // this.active_id = title === "初效滤网" ? 2 : title === "中效滤网" ? 3 : 4;
      this.active_id = 1;
      this.active = title;
      this.nowIcon =
        title === "初效滤网"
          ? this.iconItem.low
          : title === "中效滤网"
          ? this.iconItem.middle
          : this.iconItem.high;
      // this.tableData = this.resultData.filter((item) => {
      //   return item.filter_type === title;
      // });
    }
    // } else {
    //   this.tableData = [...this.resultData];
    // }
  },
  watch: {
    // "$store.state.filterState"() {
    //   this.blockList[1].num = this.$store.state.filterState.primaryFilter;
    //   this.blockList[2].num = this.$store.state.filterState.mediumFilter;
    //   this.blockList[3].num = this.$store.state.filterState.highFilter;
    // },
    // "$store.state.airState"() {
    //   this.blockList[0].num = this.$store.state.airState;
    // },
  },
};
</script>

<style scoped lang="scss">
.container {
  padding: 0 20px 20px 0;
  display: flex;
  .side-block {
    display: flex;
    flex-direction: column;
    .small-block {
      width: 280px;
      margin-top: 20px;
      height: 100px;
      cursor: pointer;
    }
  }
  .table-container {
    width: 100%;
    margin-left: 30px;
    min-width: 1200px;
    .table {
      background-color: #fff;
      padding: 15px 20px 0 20px;
      .table-info {
        min-width: 1200px;
        height: 70px;
        background-color: #ffeeee;
        border: 1px solid #ffc8c8;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .icon-container {
          width: 50px;
          height: 50px;
          border: 1px solid #ffc8c8;
          border-radius: 50%;
          background-color: #fff;
          margin-left: 20px;
          text-align: center;
          line-height: 50px;
          .icon-position {
            font-size: 30px;
            //color: #ff6c6c;
          }
        }
        .text-container {
          margin-left: 15px;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          display: flex;
          flex-direction: column;
          width: 400px;
        }
      }
      ::v-deep.el-table {
        //更换div图标
        .exchange {
          justify-content: center;
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            margin-right: 6px;
          }
        }
        .device_state {
          display: flex;
          align-items: center;
          justify-content: center;
          .el-switch {
            margin-left: 5px;
          }
        }
        .post-date {
          display: inline-block;
          width: 70px;
          height: 22px;
          background-color: #01cfa5;
          border-radius: 6px;
          font-size: 12px;
          color: #fff;
          line-height: 22px;
          text-align: center;
          cursor: pointer;
        }
        &::before {
          bottom: 100000px;
        }
        th > .cell {
          text-align: center;
          color: #333;
        }
        .el-table__row .cell {
          text-align: center;
        }
        .has-gutter > tr {
          th {
            background-color: #eaf8f8 !important;
          }
        }
        .filter-progress-btn {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background-color: #41c057;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: absolute;
          top: 6px;
          //right: 30px;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .filter-progress-btn-none {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background-color: #eaeaea;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 6px;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      ::v-deep.el-progress {
        //.el-progress-bar__outer {
        //  background-color: #999;
        //  .el-progress-bar__innerText {
        //    float: left;
        //    margin-left: 80px;
        //    margin-top: 8px;
        //  }
        //}
        position: relative;
        width: 100%;
        .el-progress-bar__outer {
          background-color: #999;
        }
        .el-progress__text {
          font-size: 12px !important;
          position: absolute;
          right: 15%;
          top: 8px;
          color: #fff;
        }
      }
    }
  }
  .filter-dialog-reset {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: space-between;
  }
}
</style>
